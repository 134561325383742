@import "assets/styles/app/include";
@import "assets/styles/vendors/include";

.minHeight {
  min-height: calc(94vh - 338px);
}

footer.footer-main {
  margin-bottom: 10px;
}

.minHeightAdmin {
  min-height: calc(100vh - 320px);
}

.minHeightArchieve {
  min-height: calc(100vh - 250px);
}

.invalid-unique {
  .cell-icon {
    width: 30px;
  }
}

.minHeightAggrid {
  min-height: calc(100vh - 205px);

  +footer.footer-main {
    margin-top: 8px;
  }
}

.allowWhiteSpaces {
  white-space: break-spaces;
  word-break: break-word;
}

.widthAuto_0 {
  width: auto !important;
}

.addNewAtifact,
.admin-view-dialog-width {
  .admin-view {
    height: 90vh;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.milestone-view {
  overflow: auto;
  height: 90vh;
}

.cm-toggle.main-artifact {
  left: 92%;
  top: 0px;
  margin-top: -23px;
  display: none;
}

.errorBorder {
  .bp3-button {
    box-shadow: inset 0 0 0 1px rgb(255, 0, 0);
  }

  .input-group-label.with-dropdown {
    box-shadow: inset 0px 0px 2px 0px rgba(255, 0, 0, 1);
  }

  .input-group.datetimepicker {
    border: 1px solid #f00;
    border-radius: 5px;
  }

  &.is-field {
    box-shadow: inset 0 0 0 1px rgb(255, 0, 0);
  }

  .selectWrap {
    box-shadow: inset 0 0 0 1px rgb(255, 0, 0) !important;
  }
}

.circle_loader {
  border: 3px solid #3498db;
  border-radius: 50%;
  border-top: 3px solid #f3f3f3;
  width: 16px;
  height: 16px;
  margin: 15px auto;
  display: block;
  -webkit-animation: loadingSpin 1s linear infinite;
  /* Safari */
  animation: loadingSpin 1s linear infinite;
}

@-webkit-keyframes loadingSpin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes loadingSpin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.noWrap {
  white-space: nowrap;
}

// tooltip css
.cst-tooltip {
  .bp3-transition-container {
    .bp3-tooltip {
      .bp3-popover-arrow {
        .bp3-popover-arrow-border {
          fill: #fff !important;
        }

        .bp3-popover-arrow-fill {
          fill: #fff !important;
        }
      }

      .bp3-popover-content {
        padding: 8px 10px;
        border-radius: 0;
        background-color: #fff;
        font-size: 14px;
        color: #000;
        box-shadow: 0 0 9px rgba(0, 0, 0, 0.25);
        border: solid 1px #c4c4c4;
      }
    }
  }
}

table.hover td.col-tools.pl-7 {
  padding-left: 7px !important;
  padding-right: 10px !important;
}

table.hover td.col-tools.trash {
  width: 46px !important;
}

.search-wrapper ul.search-controls .button.close {
  padding-top: 3px;
}

.paginationOutterWrap {
  display: none;

  >td {
    padding: 0px;
  }
}

.searchResultWrap {
  display: block !important;

  table.hover {
    tbody.sub-group-open {
      position: relative;

      .float-right {
        position: absolute;
        right: 40px;
        z-index: 20;
        top: -40px;

        span {
          text-align: left;
        }

        .ag-grid-dropdowns {
          display: inline-block;
          position: relative;

          .ag-grid-button {
            display: inline-block;
            padding: 8px 0px 8px 6px;
            cursor: pointer;
            white-space: nowrap;
            margin-top: 2px;
            font-weight: 600;

            &::after {
              content: "";
              width: 14px;
              height: 11px;
              top: 15px;
              right: -25px;
              position: absolute;
              display: inline-block;
              background: url("../src/assets/theme/images/ag_grid_hover_icon.png") no-repeat;
            }
          }

          .ag-grid-input {
            display: none;
          }

          .ag-grid-input+.ag-grid-menu {
            display: none;
          }

          .ag-grid-input:checked+.ag-grid-menu {
            display: block;
            border-radius: 4px;
            font-size: 13px;
            width: 160px;
            box-shadow: 0px 4px 40px 0px rgba(209, 208, 207, 1);
          }

          .ag-grid-menu {
            position: absolute;
            top: 100%;
            border: 1px solid #d1d0cf;
            border-radius: 4px;
            padding: 0;
            margin: 2px 0 0 0;
            box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
            background-color: #ffffff;
            list-style-type: none;
            right: -25%;

            li {
              padding: 4px 10px;
              cursor: pointer;
              white-space: nowrap;
              font-weight: 600;

              &:hover {
                background-color: #e0eef9;
                color: #000;
              }

              +.divider {
                padding: 0;
                border-bottom: 1px solid #ebebeb;
              }
            }

            li:nth-child(2) {
              margin-bottom: 5px;
            }

            li:nth-child(4) {
              margin-top: 5px;
            }

            li:nth-child(5) {
              margin-top: 5px;
            }
          }
        }
      }
    }
  }
}

.adminDashboardWrap {
  clear: both;
  display: block;
  overflow: auto;

  a:hover {
    text-decoration: none;
  }
}

.circle-loading-img {
  width: 80px;
  height: 80px;
  display: inline-block;
  vertical-align: middle;
  background-image: url(./assets/theme/images/loading_spinner.gif);
  background-repeat: no-repeat;
}

.bubble-img {
  width: 28px;
  height: 28px;
  margin-right: 5px;
  cursor: pointer;
}

.progress-bar-wrapper {
  position: relative;

  >span {
    position: absolute;
    height: 20px;
    background-color: #d8d8d8;
    left: 0px;
    top: 0px;
    z-index: 1;
    transition: all 0.3s ease-in;
    height: 100%;
  }
}

.progress-meter {
  transition: all 0.3s ease-in;
}

.progress-bar {
  position: relative;
  z-index: 2;
  padding-right: 8px;
}

.downloadLink {
  cursor: pointer;
  font-weight: bold;
  text-decoration: underline;
}

.loadingWrap {
  display: inline-block;
  margin: 0px 20px;
}

.dot-flashing {
  position: relative;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: #1e89e3;
  color: #1e89e3;
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}

.dot-flashing::before,
.dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-flashing::before {
  left: -8px;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: #1e89e3;
  color: #1e89e3;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 0s;
}

.dot-flashing::after {
  left: 8px;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: #1e89e3;
  color: #1e89e3;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dot-flashing {
  0% {
    background-color: #1e89e3;
  }

  50%,
  100% {
    background-color: #ebe6ff;
  }
}

// Three Dot Flashing

.three-dot-flashing {
  position: relative;
  width: 7px;
  height: 7px;
  border-radius: 7px;
  background-color: #1e89e3;
  color: #1e89e3;
  animation: three-dot-flashing 1s infinite linear reverse;
  animation-delay: 0.5s;
  top: 18px;
  left: -12px;
  margin-right: 14px;
}

.three-dot-flashing::before,
.three-dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}

.three-dot-flashing::before {
  top: -12px;
  left: 6px;
  width: 7px;
  height: 7px;
  border-radius: 7px;
  background-color: #1e89e3;
  color: #1e89e3;
  animation: three-dot-flashing 1s infinite reverse;
  animation-delay: 0s;
}

.three-dot-flashing::after {
  left: 12px;
  width: 7px;
  height: 7px;
  border-radius: 7px;
  background-color: #1e89e3;
  color: #1e89e3;
  animation: three-dot-flashing 1s infinite reverse;
  animation-delay: 1s;
}

@keyframes three-dot-flashing {
  0% {
    background-color: #1e89e3;
  }

  50%,
  100% {
    background-color: #ebe6ff;
  }
}

// Three Dot Flashing

.btn_select {
  outline: none;

  &.bg-light-blue:disabled {
    opacity: 0.7;
    cursor: not-allowed;
    background-color: #c9e3f9;
    color: #1e8ae4;

    &:hover {
      .check-mark-blue {
        // background-image: url("./../src/assets/theme/images/check-mark-white.png");
        //background-position: -201px -141px;
        // height: 8px;
        // width: 10px;
        // background-position: 0 0;
      }
    }
  }

  &.active {
    color: #fff;
    background-color: #1e89e3;

    .check-mark-blue {
      background-image: url(./assets/theme/images/sprite.png);
      background-position: -271px -301px;
      width: 10px;
      height: 8px;
    }

    &:hover {
      .check-mark-blue {
        // background-position: -75px -307px;
        // color: #C9E3F9;
      }
    }

    &:disabled {
      color: #fff;
      background-color: #1e89e3;

      .check-mark-blue {
        background-image: url(./assets/theme/images/sprite.png);
        background-position: -271px -301px;
        width: 10px;
        height: 8px;
      }

      &:hover {
        .check-mark-blue {
          background-position: -271px -301px;
          color: #c9e3f9;
        }
      }
    }
  }

  &.loading::before {
    display: none;
  }

  .circle_loader {
    margin: 1px 29px;
  }
}

.group-open.sub-group-open {
  .ag-theme-alpine {
    .ag-row {
      cursor: default;
    }

    .ag-root-wrapper {
      .ag-cell[col-id="agaction"] {
        .loadingWrap {
          margin: 4px 4px 0px;
        }
      }
    }
  }
}

#showallData .searchResultWrap .ag-row-even,
.searchResultWrap .ag-row-odd {
  cursor: pointer;
}

#showallData {
  &.details {
    margin-left: 2px;
    cursor: pointer;
    padding-left: 5px;
  }

  &.button.close {
    border-left: 1px solid #d1d0cf;
    border-radius: 0px;
  }

  .user-view-small {
    cursor: pointer;
  }

  .user-view-small:hover {
    background-image: url(./assets/theme/images/sprite.png);
    background-position: -389px -222px;
    width: 16px;
    height: 16px;
  }

  .searchbar-view:hover {
    background-image: url(./assets/theme/images/sprite.png);
    background-position: -366px -151px;
    width: 18px;
    height: 18px;
  }

  .searchbar-close:hover {
    background-image: url(./assets/theme/images/sprite.png);
    background-position: -366px -113px;
    width: 18px;
    height: 18px;
  }

  &.button:hover {
    opacity: 1;
  }

  .searchbar-view {
    cursor: pointer;
  }
}

.ag-header-row.ag-header-row-column {
  .ag-header-cell {
    padding: 9px 10px 9px 12px;
  }
}

.ag-pinned-right-header {
  .ag-header-row::after {
    background-color: white !important;
  }

  border-color: transparent !important;

  .ag-header-cell:nth-child(1) {
    width: 80px !important;
  }
}

table.artifactItem.artificats {
  table-layout: fixed;
  width: 100%;
  vertical-align: middle;

  .documentItemRow {
    td.title {
      .cell-text.allowWhiteSpaces {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: calc(100% - 25px);
        display: inline-block;
        vertical-align: middle;
      }

      .cell-icon {
        float: left;
      }
    }

    td.userLogin {
      .cell-text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: calc(100% - 9px);
        display: inline-block;
        vertical-align: middle;
      }

      .cell-icon {
        float: left;
      }
    }

    td.userState {
      .dataText {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        vertical-align: middle;
      }
    }
  }

  div.text-center.text-size-16.text-light-gray-3.mt-100 {
    display: block;
    text-align: center;
    margin: 0 auto;
    position: absolute;
    left: 50%;
    right: 50%;
    transform: translate(50%, 50%);
  }
}

table.artifactItem.artificats {
  table-layout: fixed;
  width: 100%;
  vertical-align: middle;

  .documentItemRow {
    td.title {
      .cell-text.allowWhiteSpaces {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: calc(100% - 25px);
        display: inline-block;
        vertical-align: middle;
      }

      .cell-icon {
        float: left;
      }
    }

    td.userLogin {
      .cell-text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: calc(100% - 9px);
        display: inline-block;
        vertical-align: middle;
      }

      .cell-icon {
        float: left;
      }
    }

    td.userState {
      .dataText {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        vertical-align: middle;
      }
    }
  }
}

.ag-body-viewport {
  .ag-pinned-right-cols-container {
    .ag-cell[col-id="preview"] {
      pointer-events: none;
      cursor: default;
    }

    .ag-cell[col-id="delaction"] {
      line-height: 20px;
      padding-top: 8px;
    }
  }
}

.ag-body-viewport {
  .ag-center-cols-clipper {
    .ag-cell[col-id="zoneicon"] {
      line-height: 20px;
    }

    .ag-cell[col-id="sectionicon"] {
      line-height: 20px;
    }

    .ag-cell[col-id="artifacticon"] {
      line-height: 20px;
    }

    .ag-cell[col-id="type"] {
      line-height: 20px;
    }
  }
}

.ag-pinned-right-cols-container {
  .ag-cell {

    .eye-preview,
    .download-light-blue-small,
    .trash-medium-gray-s {
      cursor: pointer;
    }

    .no-eye-preview {
      cursor: default;
    }
  }
}

.ag-header.ag-pivot-off {
  background-color: #fff !important;
  color: #b9bfc7;

  .ag-header-cell-label {
    color: #b9bfc7;
  }
}

.ag-center-cols-viewport {
  .ag-center-cols-container {
    min-width: 100%;

    [col-id="zoneicon"],
    [col-id="sectionicon"],
    [col-id="artifacticon"],
    [col-id="type"] {
      padding: 9px 8px;
      overflow: inherit;
    }
  }
}

.ag-center-cols-container {
  .ag-cell {
    cursor: pointer;
  }
}

.ag-row-first.ag-after-created.ag-row-focus {
  .ag-cell:nth-child(1) {
    border-left: 1px solid #b9bfc7;
  }
}

.instantiate-btn {
  height: 40px;
  width: 170px;
  outline: none;

  &:hover {
    .check-mark-round-border {
      background-position: -344px -154px;
      width: 20px;
      height: 20px;
      margin-right: 9px;
    }
  }

  .circle_loader {
    margin: 0px auto;
  }
}

.btn_export {
  min-width: 126px;
  height: 32px;
}

.displayNone {
  display: none;
}

// .form-item.checkbox input[type=checkbox] {
//     display: block;
//     opacity: 0;
//     position: absolute;
//     width: 26px;
//     height: 26px;
// }

// Multi-Select styles
.multiSelectWrap {
  position: relative;
  outline: none;
  max-width: 402px;

  &.multiSelectDisable {
    pointer-events: none;

    .selectedBox {
      color: rgba(92, 112, 128, 0.6);
    }
  }

  .listWrap {
    background-color: #fff;
    border: solid 1px #d1d0cf;
    border-top: none;
    box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15);
    border-radius: 0px 0px 4px 4px;
    display: block;
    width: 100%;
    max-height: 200px;
    margin-left: 0px;

    >li {
      padding: 6px 10px;
      color: #555555;
      text-align: left;

      &:hover,
      &.selected,
      &.focused {
        color: #000;
        background-color: #e0eef9;
        cursor: pointer;
      }

      &.disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }

  .selectWrap {
    position: relative;
    min-height: 2rem;
    box-shadow: inset 0px 0px 2px 0px rgba(0, 0, 0, 0.15);
    float: left;
    box-sizing: border-box;
    width: 100%;
    padding: 1px 5px;
    border: 1px solid #d3d2d1;
    border-radius: 4px;
    background-color: #fefefe;
    font-family: inherit;
    font-size: 0.875rem;
    font-weight: normal;
    color: #3c3c3b;
    line-height: 28px;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  .selectedBox {
    position: relative;
    border: 1px solid #d8d8d8;
    border-radius: 4px;
    background-color: #edebe8;
    cursor: default;
    color: #333;
    line-height: 17px;
    font-size: 14px;
    float: left;
    margin-right: 5px;
    margin-top: 2px;
    margin-bottom: 2px;
    padding: 3px 24px 2px 5px;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-width: auto;
    max-width: 350px;
    overflow: hidden;
  }

  .close_x_icon {
    position: absolute;
    width: 10px;
    right: 9px;
    top: 4px;
    cursor: pointer;
  }
}

.cardBox {
  display: inline-block;
  background-color: #ddd;
  border-radius: 20px;
  padding: 3px 12px;
  font-size: 13px;
  margin-right: 5px;
  margin-top: 3px;
}

.hasError {

  .bp-select.bg-white,
  .select__control {
    border: solid 2px #ff4632;
    box-shadow: inherit;
  }
}

.smallSize {
  transform: scale(0.9);
  margin-top: -4px;
  margin-left: -4px;
}

.width_100 {
  width: 100%;

  .bp3-popover-target {
    width: 100%;
  }
}

.disableWrap {

  .bp3-button:not([class*="bp3-intent-"]):disabled,
  .bp3-button:not([class*="bp3-intent-"]).bp3-disabled {
    color: #3c3c3b;
    background-color: #f5f5f5 !important;
    border: 1px solid #d3d2d1;
    box-shadow: inset 0px 0px 2px 0px rgba(0, 0, 0, 0.15);
  }
}

.scrollWrap {
  overflow: auto;

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar {
    width: 12px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #858585;
  }
}

.displayInlineBlock {
  display: inline-block !important;
}

.maxHeight_65 {
  max-height: 65vh;
}

.bg-gray-m {
  background-color: #777;

  &:hover {
    background-color: #444;
  }
}

.custom_width_percentage {
  .width_40_withMargin {
    width: calc(40% - 20px);
  }

  .width_60 {
    width: 60% !important;
  }
}

.isDisabled {
  cursor: not-allowed !important;
  opacity: 0.5;
}

.isDisabled:hover {
  cursor: not-allowed !important;
  opacity: 0.5;
}

.bp3-select-popover {
  .bp3-menu {
    max-height: 300px;
    overflow: auto;
    overflow-y: auto;
    overflow-x: hidden;
    @extend .scrollWrap;

    .dropdown-border {
      border-top: 1px solid #ebebeb;
      margin-top: 5px;
      padding-top: 5px;
    }
  }
}

.showListWrap {
  max-height: 150px;
  overflow-y: auto;
  @extend .scrollWrap;
}

.select2-results__option {
  padding-left: 20px;
}

ul[id^="select2-mileStone"] {
  .select2-results__option {
    padding-left: 30px;

    &:first-child {
      padding-left: 20px;
    }
  }

  li[id$="_milestoneGroup_"] {
    background: #f4f4f4;
    padding-left: 20px;
  }
}

.textDecorationNone {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

.lineHeight-30 {
  line-height: 30px;
}

.bp3-overlay-scroll-container .bp3-overlay-content {
  max-height: 100vh;
  overflow-y: auto;
  pointer-events: auto;
}

.blueProgressBar {
  clear: both;
  position: relative;
  top: 8px;
}

.artifactItem {
  td:nth-child(3) {
    width: 150px;
    height: 38px;
  }

  td:nth-child(4) {
    width: 103px;
    height: 38px;
  }

  td:nth-child(5) {
    width: 103px;
    height: 38px;
  }
}

.artifactRow {
  .check-mark-green-xxs {
    margin-right: 5px;
  }
}

.textOverFlow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.outlineNone {
  outline: none !important;
}

.archiveDashboardWrap {
  .overlay-loading .container {
    width: 560px;
  }
}

.document-preview {
  .status-bar.file-sharing {
    .cell-text {
      width: 80%;

      .sharing-count {
        display: flex;
        padding-right: 0px !important;

        .available-binder {
          border-left: 1px solid #ccc;
          padding-left: 10px;
          padding-right: 13px;

          i {
            margin-right: 6px;
          }
        }

        .completed-count {
          border-left: 1px solid #ccc;
          padding-left: 15px;
        }

        .completed-count,
        .incomplete-binder {
          padding-right: 15px;

          i {
            margin-right: 6px;
          }
        }
      }
    }
  }
}

.header-archive-learning {
  position: relative;
}

.newui-topalign{
  margin-top: 9px;
}

.newui-button-container {
  &.newui-button-position{
    position: absolute;
    right: 25px;
    top: 2px;
  }
  outline: none;
  .newui-container {
    width:116px ;
    height: 30px;
    background: #1E8AE4;
    color:#fff;
    padding: 6px 10px 6px 8px;
    display: flex;
    align-items: center;
    outline: none;
    border-radius: 6px;
    cursor: pointer;
  }
  .newui-button-prefixicon {
    width: 16px;
    height: 18px;
    border-radius: 6px;
    cursor: pointer;
    margin-right: 6px;
  }
  .newui-button-text {
    margin-top: 2px;
    font-weight: 600;
    font-size: 14px;
  }
  .popup-button-container {
    background: #1E8AE4;
    color: #fff;
    padding: 8px 12px 8px 10px;
    display: flex;
    align-items: center;
    outline: none;
    cursor: pointer;
    border-radius: 6px;
  }
}

@-moz-document url-prefix() {
  .addHeight {
    min-height: 190px;
    // background-color: red;
  }

  .addTableHeight {
    height: calc(100% - 41.8px) !important;
  }

  .height_0 {
    height: 0 !important;
  }
}

@media all and (-ms-high-contrast: none) {
  .multiSelectWrap .close_x_icon {
    top: -64px;
    z-index: 9999;
  }
}

table.artifactItem {
  tr.artifactRow {
    height: 48px;

    td {
      vertical-align: middle;
    }
  }
}

#link_uploadedFile_undefined {
  .drop-zone-drop-area {
    border: 3px dashed #bbdcf7;
    color: #1e8ae4;
    opacity: 0.5;

    &:hover {
      color: #1e8ae4;
      opacity: 1;
      border: 3px dashed #1e8ae4;
    }
  }
}

.form-data-table.with-actions {
  .milestone-description {
    word-break: break-all;
  }
}

.react-datepicker__header__dropdown.react-datepicker__header__dropdown--scroll {
  display: none !important;
}

.document-actions {
  .eye-preview {
    cursor: pointer;
  }
}

.sharing-document-container .popup-container {
  width: 75vw !important;
  max-width: 1440px;
}

.popup-flex-container {
  display: flex;

  >div.pdf-preview {
    flex: 9 1;
    // margin-left: 10px;
  }

  .skew-divider {
    flex: 0 1;
    height: 90vh;
    z-index: 1005;
    border-right: 1px solid #e1e1e1;

    .skew-driver {
      left: 50%;
    }
  }

  .popup-header-content {
    flex: 9 1;
    height: 90vh;
    overflow: auto;
  }

  .pdf-preview.bmp-img {
    text-align: center;
    margin-left: 15px;
    margin-right: 15px;
    max-width: 40vw;
    height: 90vh;
    position: relative;

    img {
      position: absolute;
      margin: auto;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }

  .d-flex {
    display: flex;
  }

  .bdr-rgt {
    border-right: 1px solid #d9d9d9;
  }

  .popup-header-content.document-sharing {
    min-height: 62vh;
    max-height: 92vh;
    height: auto;
    padding: 30px;

    .popup-header-content {
      background-color: #fff;

      .circle_loader {
        position: relative;
        top: 35%;
      }
    }

    .popup-sharing-binder {
      padding: 10px 15px 10px;
      background-color: #fff;
      margin-top: 15px;
      border: 1px solid #d9d9d9;
      border-bottom: 0px;
    }

    .checkbox-action {
      display: flex;
      position: relative;
      padding-left: 28px;
      cursor: pointer;
      font-size: 22px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      height: 20px;
      align-items: center;

      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 20px;
        width: 20px;
        top: 0;
        left: 0;
        z-index: 9999;
      }

      input:disabled {
        cursor: not-allowed;
      }

      .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px;
        background-color: #fff;
        border: 1px solid #858585;
        border-radius: 4px;

        &::after {
          content: "";
          position: absolute;
          display: none;
        }
      }

      .checkmark-disabled {
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px;
        background-color: #fff;
        border: 1px solid #858585;
        border-radius: 4px;

        &::after {
          content: "";
          position: absolute;
          display: none;
        }
      }
    }

    .checkbox-action input:checked~.checkmark {
      background-color: #fff;
      border: 1px solid #858585;
      border-radius: 4px;
    }

    .checkbox-action input:checked~.checkmark-disabled {
      background-color: #f1f1f1;
      border: 1px solid #f1f1f1;
      border-radius: 4px;
    }

    .checkbox-action input:checked~.checkmark:after {
      display: block;
    }

    .checkbox-action input:checked~.checkmark-disabled:after {
      display: block;
    }

    .checkbox-action .checkmark:after {
      left: 6px;
      top: 3px;
      width: 6px;
      height: 10px;
      border: solid #000;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    .checkbox-action .checkmark-disabled:after {
      left: 6px;
      top: 3px;
      width: 6px;
      height: 10px;
      border: solid #706f6f;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    .pop-subheader {
      background: #fff;
      padding: 15px;
      border: 1px solid #d9d9d9;
      border-radius: 4px;

      .table {
        .cell-icon {
          padding-right: 15px;
          width: 25px;
        }

        .sharing-dropdown {
          display: flex;
          position: relative;
          justify-content: flex-end;
          margin-right: 2px;

          .download-action {
            display: flex;
            align-items: baseline;
            justify-content: center;
            line-height: 0.7;
            background-color: #d9d9d9;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            text-align: center;
            font-size: 23px;
            color: #000;
            cursor: pointer;
            white-space: nowrap;
            outline: none;
          }

          .sharing-drop {
            display: inline-block;
            cursor: pointer;
            position: absolute;
            top: 40px;
            right: 14px;
            width: 230px;

            ul {
              background-color: #fff;
              box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
              width: 250px;
              border-radius: 6px;
              border: 1px solid #d1d0cf;
              padding: 5px;

              li {
                padding: 5px 8px;

                &:hover {
                  background-color: #e0eef9;
                  border-radius: 6px;
                }
              }
            }
          }
        }


        .loadingWrap {
          margin-right: 0px;
        }
      }

      .filesharing-header {
        .sub-header {
          font-size: 12px;
          padding-right: 10px;
          margin-right: 12px;
          line-height: 1;
          font-weight: 500;

          .org-name {
            white-space: nowrap;
            max-width: 175px;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          p:nth-child(1) {
            color: #706f6f;
          }

          p:nth-child(2) {
            color: #3c3c3b;
            padding-left: 5px;
          }
        }
      }

      .binder-details-view {
        display: flex;
        border-top: 1px dashed #e0e0e0;
        padding-top: 10px;
        margin-top: 10px;

        .binder-status {
          display: flex;
          width: 47px;
          padding-left: 6px;
        }

        .binder-content {
          display: flex;
          flex-direction: column;
          width: 100%;

          p {
            margin-bottom: 0px;
          }

          .binder-header {
            display: flex;

            span:nth-child(odd) {
              color: #706f6f;
            }

            span:nth-child(even) {
              color: #3c3c3b;
              padding-right: 8px;
            }

            >span {
              padding-right: 8px;
              color: #706f6f;
            }
          }
        }
      }

      .circle-dot {
        width: 30px;
        height: 30px;
        background-color: #d9d9d9;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 16px;
        padding-bottom: 12px;
        color: #000;
        font-size: 24px;
        cursor: pointer;
      }
    }

    .popup-content {
      padding-bottom: 0px;

      .ag-header-row.ag-header-row-column .ag-header-cell {
        height: 29px;
        background-color: #ececec;
      }

      .sharingfiles-area {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        background-color: #fff;
        border: 1px solid #d8d8d8;
        border-radius: 4px;
        justify-content: center;
        height: calc(70vh - 30px);

        .create-sharing {
          height: 70vh;
          align-items: center;
          display: flex;
        }

        .sharing-info {
          border-top: 1px solid #d8d8d8;
          display: flex;
          padding: 15px;

          .cell-icon {
            display: flex;
            align-items: center;
            margin-left: 8px;
          }
        }
      }

      .ag-theme-alpine {
        .ag-root-wrapper {
          border-top: 0px;

          .ag-header {
            height: 30px !important;
            min-height: 30px !important;

            .ag-header-viewport {
              background: #ececec;

              .ag-header-row.ag-header-row-column {
                height: 30px !important;

                .ag-header-cell {
                  padding: 7px 12px 7px 18px;
                  height: 30px;

                  color: #646464;
                }
              }
            }
          }

          .ag-body-viewport {
            .ag-center-cols-viewport {
              .ag-row {
                border-bottom-style: dashed;
              }
            }

            .ag-center-cols-clipper {
              .ag-center-cols-container {

                .ag-cell[col-id="recipientsName"],
                .ag-cell[col-id="coSignatoriesName"],
                .ag-cell[col-id="binderCreatedDate"],
                .ag-cell[col-id="viewedCount"],
                .ag-cell[col-id="signedCount"],
                .ag-cell[col-id="status"] {
                  cursor: default;
                }

                .ag-cell[col-id="status"] {
                  .ag-react-container {
                    text-align: center;
                    margin-left: -12px;
                  }
                }
              }
            }
          }

        }
      }

      .right-arrow-binder {
        background: #d9d9d9;
        width: 30px;
        border-radius: 50%;
        height: 30px;
        margin-top: 5px;
        margin-left: 8px;

        i {
          margin-left: 8px;
          margin-bottom: 13px;
          margin-top: 0;
        }
      }

      .sharingfiles-area a {
        color: #000;
        display: flex;
        text-decoration: none;

        .nowclick {
          margin-left: 10px;
        }
      }

      .sharingfiles-step {
        margin-top: 20px;
        height: auto;
        background-color: #fff;
        border: 1px solid #d8d8d8;
        border-radius: 4px;

        .step-header {
          padding: 15px;
          border-bottom: 1px solid #e0e0e0;

          >div {
            align-items: center;

            p:nth-child(1) {
              font-size: 18px;
            }

            p {
              display: flex;
              margin-bottom: 0px;
              align-items: center;
              font-weight: 500;
            }

            p:nth-child(2) {
              display: flex;
              justify-content: flex-end;
              margin-left: auto;
              font-size: 14px;
            }
          }
        }

        .step-container {
          .sharing-first-form {
            padding: 25px 20px;

            .radio-button {
              display: block;
              position: relative;
              padding-left: 28px;
              margin-bottom: 12px;
              margin-left: 10px;
              cursor: pointer;
              font-size: 22px;
              -webkit-user-select: none;
              -moz-user-select: none;
              -ms-user-select: none;
              user-select: none;

              input {
                position: absolute;
                opacity: 0;
                cursor: pointer;
              }

              input:checked~.checkmark:after {
                display: block;
              }

              .custom-control-input {
                &:disabled {
                  +.checkmark {
                    background-color: #f1f1f1;
                    cursor: not-allowed;
                  }

                  span {
                    color: #f1f1f1;
                  }
                }
              }

              /* Create a custom radio button */
              .checkmark {
                position: absolute;
                top: 0;
                left: 0;
                height: 20px;
                width: 20px;
                background-color: #fff;
                border: 1px solid #dcdcdc;
                border-radius: 50%;

                &::after {
                  top: 5px;
                  left: 5px;
                  width: 8px;
                  height: 8px;
                  border-radius: 50%;
                  background: #1d1d1b;
                }

                &::after {
                  content: "";
                  position: absolute;
                  display: none;
                }
              }

              &:hover input~.checkmark {
                background-color: #f5f5f5;
              }

              input:checked~.checkmark {
                background-color: #fff;
              }
            }

            .w-19 {
              width: 19%;
            }

            .w-60 {
              width: 60%;
            }

            .label-text {
              label {
                color: #858585;
                font-size: 14px;
                font-weight: 500;
              }
            }

            .sharing-recipient {
              justify-content: flex-start;

              .sharing-option {
                justify-content: flex-start;
                flex-direction: column;

                >div:nth-child(1) {
                  padding-left: 10px;
                }
              }
            }

            .signing-sharing {
              padding-top: 25px;

              .signing-input {
                padding-left: 20px;

                input {
                  margin-right: 10px;
                }

                .radio-button {
                  margin-left: 0px;
                }
              }

              .request-signature {
                padding-left: 20px;
              }
            }

            .add-signatory {
              padding-top: 25px;

              input {
                margin-right: 10px;
              }

              .radio-button {
                margin-left: 0px;
              }
            }
          }

          .sharing-user-disabled {
            padding-top: 10px;
            padding-left: 20px;

            >input {
              max-width: 618px;
              height: 35px;
              padding-left: 10px;
            }

            .create-binder-seperate {
              display: flex;
              align-items: center;
              margin-top: 10px;

              label {
                margin-left: 0px;
                color: #000;
              }
            }

            .css-yk16xz-control,
            .css-1pahdxg-control {
              min-height: 26px;

              div:nth-child(1) {
                >div:last-child {
                  height: 26px;

                  >div {
                    input {
                      box-shadow: none;
                      height: 26px !important;
                    }
                  }
                }
              }

              >div:nth-child(2) {
                span {
                  display: none;
                }

                div {
                  display: none;
                }
              }
            }

            div {
              div:nth-child(2) {
                div {
                  label {
                    cursor: pointer;
                  }
                }
              }
            }
          }

          .request-signature {
            margin-top: 10px;

            .css-yk16xz-control,
            .css-1pahdxg-control {
              min-height: 26px;

              div:nth-child(1) {
                >div:last-child {
                  >div {
                    input {
                      width: 0px !important;
                      height: 0px !important;
                    }
                  }
                }
              }

              >div:nth-child(2) {
                span {
                  display: none;
                }

                div {
                  display: none;
                }
              }
            }

          }

          .to-add-co-signatory {
            margin-top: 10px;

            .css-yk16xz-control,
            .css-1pahdxg-control {
              min-height: 26px;

              div:nth-child(1) {
                >div:last-child {
                  height: 26px;

                  >div {
                    input {
                      height: 26px !important;
                      box-shadow: none;
                    }
                  }
                }
              }

              >div:nth-child(2) {
                span {
                  display: none;
                }

                div {
                  display: none;
                }
              }
            }

            div {
              div:nth-child(2) {
                div {
                  label {
                    cursor: pointer;
                  }
                }
              }
            }
          }
        }

        .step-footer {
          border-top: 1px solid #e0e0e0;
          padding: 15px;
          display: flex;

          .cancel-option {
            display: flex;
            cursor: pointer;

            .back-button {
              padding: 8px 15px;
              border: 1px solid #e0e0e0;
              margin-top: 10px;

              span {
                padding-left: 10px;
              }
            }

            button {
              outline: none;
              padding-left: 10px;
              color: #858585;
              cursor: pointer;
            }
          }

          .submit-option {
            display: flex;
            justify-content: flex-end;
            margin-left: auto;
            cursor: pointer;

            button {
              cursor: pointer;
              outline: none;
              padding-right: 10px;
              color: #1e8ae4;
              overflow: hidden;
              text-overflow: ellipsis;
              width: 71px;
              white-space: nowrap;
            }
          }
        }

        #circlebox {
          border-radius: 10px;
        }

        select.step1-select {
          height: 30px;
          max-width: 74%;
          color: #999
        }

        .list-of-binder {
          .binder-headerline {
            justify-content: flex-start;
            align-items: center;
            width: 100%;
            margin: 10px 20px;
            font-size: 14px;
            height: 15px;
            font-weight: 500;

            p {
              margin-bottom: 0px;
              margin-right: 10px;
              border-right: 1px solid #d9d9d9;
              line-height: 1;

              span.binder-heading {
                color: #706f6f;
                padding-right: 5px;
              }

              span:nth-child(2) {
                color: #3c3c3b;
                padding-right: 10px;
              }
            }

            p:last-child {
              border-right: 1px solid transparent;
            }
          }
        }

        .binder-list {
          padding: 10px 20px;
          font-size: 14px;
          line-height: 1.6;
          font-weight: 500;

          .header-confirmation {
            color: #706f6f;
          }

          .binder-details {
            color: #1d1d1b;
          }
        }
      }
    }

    .step-footer {
      display: flex;

      .cancel-option {
        display: flex;
        outline: none;
        width: 100%;

        .back-button {
          padding: 13px 15px;
          border: 1px solid #e0e0e0;
          cursor: pointer;
          color: #706f6f;
          border-top: 0;
          display: flex;
          align-items: center;
          width: 100%;

          span {
            padding-left: 10px;
          }
        }
      }
    }

    .table-wrapper {
      width: 100%;
      max-width: 1440px;
      margin: 0 auto;
      border: 1px solid #e0e0e0;
    }

    /* Table column sizing
    ================================== */
    .date-cell {
      width: 27%;
    }

    .shared-date,
    .viewed-date,
    .signed-date {
      width: 21%;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .status-cell {
      width: 10%;
    }

    /* Apply styles
    ================================== */
    .Rtable {
      display: flex;
      flex-wrap: wrap;
      margin: 0;
      padding: 0;

      .Rtable-row {
        width: 100%;
        display: flex;

        .Rtable-cell {
          box-sizing: border-box;
          flex-grow: 1;
          padding: 0.8em 1em;
          overflow: hidden; // Or flex might break
          list-style: none;
          font-size: 14px;
          font-weight: 500;
          color: #1d1d1b;

          &.column-heading {
            background-color: #e0e0e0;
            color: #646464;
            padding: 5px 13px;
            font-size: 13px;
            font-weight: 500;
          }

          &.status-cell {
            text-align: center;

            div {
              text-align: center;
            }
          }
        }

        &.Rtable-body {
          border-bottom: 1px dashed #e0e0e0;
        }
      }
    }

    .binder-properties-history {
      .form-block {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}

.footer-container {
  width: 100%;

  .footer-wrapper {
    display: flex;
    justify-content: space-between;
  }
}

.pop-subheader .table .cell-text.text-right {
  vertical-align: middle;
}

.sharefiles-Box {
  margin-top: 10px;
  background-color: #f8f7f6;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
}

.pop-subheader .table {
  p {
    margin-bottom: 0px;
  }

  p bold {
    font-weight: 200;
  }

  .shared-filename {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.2;
  }
}

.cancel-continue-flex {
  display: flex;
  padding: 22px;
  border: 1px solid #d8d8d8;
  margin-top: 7px;
  border-right: none;
  border-left: none;
  border-bottom: none;
}

table.form-data-table {
  tbody {
    tr {
      td.col-label {
        width: 35%;
      }

      td.col-content {
        width: 72%;
      }
    }
  }
}

.pdf-viewer.video-file {
  margin: 0 auto;
  position: relative;
  width: 620px;
}

.popup-mini-container {
  .popup-mini-content {
    .block-wrapper {
      textarea[name="comment"] {
        min-width: 298px;
        max-width: 332px;
        max-height: 200px;
        min-height: 80px;
      }
    }
  }
}

@media screen and (min-width: 1650px) and (max-width: 1850px) {
  .popup-flex-container {
    .pdf-preview {
      .pdf-viewer.video-file {
        width: 765px;
      }
    }
  }
}

@media screen and (min-width: 1851px) and (max-width: 2100px) {
  .popup-flex-container {
    .pdf-preview {
      .pdf-viewer.video-file {
        width: 860px;
      }
    }
  }
}

@media screen and (min-width: 2101px) and (max-width: 2800px) {
  .popup-flex-container {
    .pdf-preview {
      .pdf-viewer.video-file {
        width: 960px;
      }
    }
  }
}

.pdf-preview {
  .pdf-header {
    text-align: center;
    padding-top: 16px;

    p {
      display: inline-block;
      font-weight: 600;
    }

    i {
      float: right;
      cursor: pointer;
    }
  }

  .pdf-viewer {
    margin: 0 auto;
    width: 100%;
    height: 90vh;
  }
}

.radio-document {
  div {
    width: 120%;
  }
}

.popup-max-width-1200 {
  .radio-document {
    div {
      width: 190%;
    }
  }
}

@media screen and (min-width: 1651px) and (max-width: 1800px) {
  .popup-max-width-1200 {
    .radio-document {
      div {
        width: 135%;
      }
    }
  }
}

@media screen and (min-width: 1801px) and (max-width: 1980px) {
  .popup-max-width-1200 {
    .radio-document {
      div {
        width: 121%;
      }
    }
  }
}

@media screen and (min-width: 1981px) {
  .popup-max-width-1200 {
    .radio-document {
      div {
        width: 117%;
      }
    }
  }
}

.popup-header-content.document-preview {
  width: 836px;

  .popup-content {
    .form-block {
      padding-left: 45px;
      padding-right: 0px;

      .popup-document-name {
        margin-bottom: 20px;

        .radio-document {
          label.bp3-control {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .allowWhiteSpaces {
    width: initial;
  }

  p {
    width: auto;
    word-break: break-word;
  }

  .list-w-dividers {
    width: 740px;
    word-break: break-word;
  }
}

#input_title_text,
#multi_select_document {
  width: 365px;
}

.App:not(.is-in-desktop-only-mode) .HeaderItems,
.App:not(.is-in-desktop-only-mode) .CustomElement {
  background-color: #f7f7f7;
}

@media (max-width: 700px) {
  .document-preview {
    .popup-content {
      .form-block {
        padding-left: 0px;
      }
    }
  }
}

.popup-flex-container.pdf-tmf-dropzone {
  .popup-header-content.document-preview {
    height: calc(600px - 15px);
    padding-bottom: 0px;

    .popup-content {
      padding-bottom: 0px;
    }
  }
}

.popup-flex-container.pdf-tmf-dropzone.docFormStatePdf {
  .popup-header-content.document-preview {
    height: 90vh;
    overflow: auto;
  }
}

.popup-max-width-1200 {
  .popup-flex-container.pdf-tmf-dropzone {
    .popup-header-content.document-preview {
      height: 90vh;
    }
  }

  .popup-flex-container {
    .popup-header-content.document-preview {
      width: 650px;
      z-index: 1000;
    }
  }
}

.pdf-doc {
  .popup-container {
    .popup-header-main {
      .grid-x {
        position: relative;
        z-index: 99;
      }
    }

    .callout.alert {
      margin-bottom: 0px;
    }

    .callout.alert+.popup-flex-container {
      .popup-header-content {
        height: calc(90vh - 55px);
      }

      .skew-divider {
        height: calc(90vh - 55px);
      }

      .pdf-preview {
        .pdf-viewer {
          height: calc(90vh - 55px);
        }

        &.zip-folder {
          .pdf-viewer {
            height: calc(84vh - 55px);
          }
        }
      }

      .pdf-preview.zip-folder {
        .file-structure {
          height: calc(98vh - 146px);
        }
      }
    }
  }
}

section.form-block {
  .grid-x {
    .small-4 {
      width: 30%;
      padding-right: 20px;

      label {
        color: #858585;
        text-align: right;
        line-height: 1.3;
      }
    }

    .small-8 {
      width: 61%;
    }
  }
}

.popup-max-width-1200 {
  .originalfilelbl {
    height: 20px;

    .originalfile {
      width: 405px !important;
      position: relative;
      bottom: 28px;
      left: 30%;
    }
  }

  .document-name {
    height: 22px;
  }
}

.progress.blueProgressBar {
  max-width: 365px;
}

.clear-fix {
  clear: both;
}

.artifact-name {
  width: 470px !important;
  max-width: 470px;

  input {
    width: 470px;
    max-width: 470px !important;
  }
}

.w-500 {
  width: 500px;
}

.w-850 {
  width: 850px;
}

td.file-link {
  word-break: break-all;

  .file-link-grey {
    margin-right: 7px;
  }
}

.field-wrapper.field-input-wrapper {
  display: inline-block;
  margin-right: 0px;
  max-width: 448px !important;
}

.field-wrapper.field-input-wrapper+input {
  top: 5px;
  margin-left: 5px;
}

input[name="artifact.name"] {
  width: 470px;
  max-width: 470px !important;
}

@media screen and (min-width: 1450px) {
  .popup-max-width-1200 {
    .originalfilelbl {
      .originalfile {
        width: 405px !important;
        position: relative;
        bottom: 0px;
        left: 0px;
      }
    }
  }
}

/* Toggle button */

.cm-toggle {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  position: relative;
  border: 0;
  outline: 0;
  cursor: pointer;
  display: none;
}

.cm-toggle:after {
  content: "";
  width: 26px;
  height: 16px;
  display: inline-block;
  background: #cdcdcd;
  border-radius: 18px;
  clear: both;
}

.cm-toggle:before {
  content: "";
  width: 12px;
  height: 12px;
  display: block;
  position: absolute;
  left: 2px;
  top: 2px;
  border-radius: 50%;
  background: #fff;
}

.cm-toggle:checked:before {
  left: 12px;
}

.cm-toggle:checked:after {
  background: #1e8ae4;
}

@media screen and (max-width: 1023px) and (min-width: 650px) {
  .cm-toggle.main-artifact {
    left: 85%;
  }
}

/* Toggle button */
.popup-flex-container-resize {
  height: 91vh;

  .resize-handle-horizon {
    width: 1px !important;
    height: 91vh !important;
    background-color: #d8d8d8 !important;
    cursor: col-resize !important;
  }

  .resize {
    top: 44px !important;
  }

  .resizeHorizonDocProperties {
    height: 91vh;
    overflow: auto !important;
  }
}

.popup-max-width-860 {
  .resize-handle-horizon {
    display: none;
  }

  .resizeHorizonPreview {
    display: none !important;
  }
}

.popup-max-width-1200 {
  max-width: 90%;
  width: 90%;
}

button.sharelinkbutton {
  word-break: break-all;
  color: #1e8ae4;
  text-align: left;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  &:focus {
    outline: none;
  }
}

table tbody.group .cell-icon .sprite.file-zip {
  margin-right: 9px;
}

.pdf-preview.txt-format {
  padding: 10px;
  border: 1px solid #d8d8d8;
  margin: 10px;
  height: 85vh;
  word-break: break-all;
  background-color: #fff;

  div {
    height: 81vh;
    overflow: auto;
  }
}

.pdf-preview.zip-folder {
  .pdf-viewer {
    height: 84vh;

    .file-structure {
      background-color: #fff;
      border: 2px solid #d8d8d8;
      padding: 0px 8px 8px 8px;
      margin-top: 20px;
      width: calc(50vw - 125px);
      height: calc(98vh - 100px);
      overflow: auto;

      /* width */
      &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #fff;
        border-radius: 10px;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #b4b4b4;
        border-radius: 10px;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #b4b4b4;
        border-radius: 10px;
      }

      .zip-preview-heading {
        margin-bottom: 0px;

        thead {
          border: 0px;
          z-index: 1;
          background-color: #fff;
          position: sticky;
          top: 0;

          th {
            font-weight: normal;
            padding: 10px 0px 10px 0px;
            font-size: 13px;
            position: sticky;
            top: 0;
            z-index: 2;
            background-color: #fff;
            color: #858585;

            &:nth-child(1) {
              max-width: 320px;
              width: 320px;
            }

            &:nth-child(2) {
              max-width: 150px;
              width: 150px;

              span {
                padding-left: 12px;
              }

              &::before {
                border-right: 1px solid #ebebeb;
                content: "";
              }
            }

            &:nth-child(3) {
              max-width: 150px;
              width: 150px;

              span {
                padding-left: 12px;
              }

              &::before {
                border-right: 1px solid #ebebeb;
                content: "";
              }
            }

            &:nth-child(4) {
              max-width: 90px;
              width: 90px;

              span {
                padding-left: 12px;
              }

              &::before {
                border-right: 1px solid #ebebeb;
                content: "";
              }
            }
          }
        }
      }

      .zip-preview-body {
        tr {
          background-color: #fff;
        }

        td {
          padding: 0px;
          background-color: #fff;
          line-height: 2;
          font-size: 12px;
          padding: 5px 0px 0px;
          color: #3c3c3b;

          &:nth-child(1) {
            max-width: 320px;
            min-width: 320px;
            width: 320px;
            display: inline-block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            vertical-align: middle;
            margin-right: 12px;

            span {
              margin-left: 12px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              vertical-align: middle;
            }
          }

          &:nth-child(2) {
            max-width: 150px;
            min-width: 150px;
            width: 150px;
            margin-right: 15px;
            padding-right: 15px;
            padding-left: 12px;
          }

          &:nth-child(3) {
            max-width: 150px;
            min-width: 150px;
            width: 150px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            vertical-align: middle;
            margin-right: 15px;
            padding-right: 15px;
            padding-left: 15px;
          }

          &:nth-child(4) {
            max-width: 90px;
            min-width: 90px;
            width: 90px;
            text-align: right;
            padding-right: 15px;
          }
        }
      }
    }
  }
}

.Modal.WarningModal.open {
  .header-text {
    padding: 16px 10px;
  }

  .body {
    padding: 67px 16px;
  }
}

.field-wrapper.field-input-wrapper.large-12 {
  input[type="text"] {
    width: 94%;
    display: inline-block;
  }
}

.list-inline.folder-path.header-doc {
  display: flex;
  justify-content: center;
  align-items: center;
}

.new-version {
  .container-radio {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    p {
      font-size: 14px;
      line-height: 1.6;
    }
  }

  .container-radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 22px;
    width: 22px;
    background-color: #f5f5f5;
    border: 1px solid #333;
    border-radius: 50%;
  }

  .container-radio:hover input~.checkmark {
    background-color: #f3f3f3;
  }

  .container-radio input:checked~.checkmark {
    background-color: #f2f2f2;
    border: 1px solid #333;
    box-shadow: -1px -1px 5px 0px rgba(0, 0, 0, 0.43);
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  .container-radio input:checked~.checkmark:after {
    display: block;
  }

  .container-radio .checkmark:after {
    top: 6px;
    left: 7px;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #000;
  }
}

ul.folder-path.header-doc {
  li:nth-child(2) {
    margin-top: -4px;
  }

  li:nth-child(3) {
    margin-top: -4px;
  }

  li.version-window {
    padding-top: 0px;
    padding-bottom: 0px;
    margin-top: -9px;

    .version-dropdown {
      width: 75px;
      height: 22px;

      div:nth-child(1) {
        >div.css-yk16xz-control {
          background-color: transparent;
          min-height: 22px;
          border-color: #ffffff;
          width: 50px;
          cursor: pointer;

          div:nth-child(1) {
            display: block;
            padding: 0px;
            margin-left: 5px;
            color: #fff;

            >div {
              pointer-events: none;

              input[type="text"] {
                box-shadow: none;
                caret-color: transparent;
                cursor: pointer;
                pointer-events: none;
              }
            }
          }

          div:nth-child(2) {
            margin: 0px;
            padding: 0px;
            margin-bottom: 3px;
            height: 23px;

            span {
              width: 0px;
            }
          }

          >div:nth-child(2) {
            >div {
              // background-image: url("../assets/theme/images/arrow.png");
              content: '';
              border-color: #f1f1f1 transparent transparent transparent;
              border-width: 5px 4px 0px 4px;
              width: 0px;
              border-style: solid;
              top: 9px;
              position: relative;
              cursor: pointer;
              right: 8px;

              svg {
                display: none;
              }
            }
          }
        }

        .css-1pahdxg-control {
          background-color: transparent;
          min-height: 22px;
          border-color: #ffffff;
          width: 50px;
          cursor: pointer;

          >div:nth-child(1) {
            display: block;
            padding: 0px;
            margin-left: 8px;
            color: #fff;

            >div:nth-child(1) {
              color: #fff;
            }

            >div {
              pointer-events: none;

              input[type="text"] {
                box-shadow: none;
                caret-color: transparent;
                cursor: pointer;
                pointer-events: none;
              }
            }
          }

          div:nth-child(2) {
            margin: 0px;
            padding: 0px;
            margin-bottom: 3px;
            height: 23px;

            span {
              width: 0px;
            }
          }

          >div:nth-child(2) {
            >div {
              content: '';
              border-color: #f1f1f1 transparent transparent transparent;
              border-width: 5px 4px 0px 4px;
              width: 0px;
              border-style: solid;
              top: 9px;
              position: relative;
              right: 8px;

              svg {
                display: none;
              }
            }
          }

          &:hover {
            border-color: #ffffff;
          }
        }
      }
    }
  }
}

.document-progress-bar {
  .float-right {
    width: 157px;

    a {
      margin-right: 13px;
    }
  }
}

.text-medium-dark-gray {
  .float-right {
    height: 25px;
    line-height: 25px;
  }
}

.zoneData.group.zoneItem {
  .title {
    .cell-text {
      word-wrap: break-word;
      word-break: break-word;
    }
  }
}

.status-bar.note-list {
  border-radius: 0px 0px 4px 4px;
  border-top: 0px;
  padding: 0;

  .status-row.note-listed {
    display: flex;
    padding: 5px 10px 5px;
    border-top: none;
    border-bottom: 1px solid #d8d8d8;

    &:last-child {
      border-bottom: 0px;
    }

    div:nth-child(1) {
      padding: 0px 14px 0px 8px;
    }

    .noteListUser {
      overflow: hidden;
      word-wrap: break-word;
      flex: 1;
      font-size: 12px;
      color: #858585;

      span {
        display: block;
        font-size: 16px;
        color: #1d1d1b;
      }
    }

    .noteList {
      font-size: 14px;
    }
  }
}

.ag-popup {
  .ag-tooltip-custom {
    .custom-tooltip {
      background-color: #f3f3f3;
      padding: 5px 20px;
      color: #000;
      font-size: 15px;
      border-radius: 10px;
    }
  }
}


@media screen and (min-width: 1351px) and (max-width: 1649px) {
  .pdf-preview.zip-folder {
    .pdf-viewer {
      .file-structure {
        width: calc(49vw - 125px);
        height: calc(98vh - 100px);
      }
    }
  }
}

@media screen and (min-width: 1650px) and (max-width: 1850px) {
  .document-progress-bar {
    .float-right {
      width: 175px;

      a {
        margin-right: 13px;
      }
    }
  }

  .pdf-preview.zip-folder {
    .pdf-viewer {
      .file-structure {
        width: calc(48vw - 125px);
        height: calc(98vh - 100px);
      }
    }
  }
}

@media screen and (min-width: 1851px) and (max-width: 2450px) {
  .document-progress-bar {
    .float-right {
      width: 185px;

      a {
        margin-right: 20px;
      }
    }
  }

  .pdf-preview.zip-folder {
    .pdf-viewer {
      .file-structure {
        width: calc(47vw - 125px);
        height: calc(95vh - 100px);
      }
    }
  }
}

@media screen and (min-width: 2451px) and (max-width: 3450px) {
  .pdf-preview.zip-folder {
    .pdf-viewer {
      .file-structure {
        width: calc(46vw - 125px);
        height: calc(93vh - 100px);
      }
    }
  }
}

.document-prop-datepicker {
  .popup-content {
    .form-block {
      .datetimepicker.hasError {
        .input-group-label.with-dropdown {
          .bp3-popover-wrapper {
            .bp3-popover-target {
              button {
                min-height: 28px;
                box-shadow: none;
              }
            }
          }
        }
      }
    }
  }
}

.ag-pinned-right-header .ag-header-cell[col-id="preview"] {
  width: auto !important;
}

.cusor-pointer {
  cursor: pointer;
}

.document-name {
  .small-8 {
    input[type='checkbox'] {
      accent-color: #fff;
      box-shadow: 0px 0px 6px -2px #706f6f;
      border: none;
    }
  }
}

.popup-sharing-econsent {
  display: flex;

  .popup-sharing-enable-clinic {
    display: flex;
    align-items: center;
  }
}

.doc-version-input {
  #input_verion {
    width: 102px;
  }
}

.document-preview .popup-content .form-block:nth-child(2) .grid-x:last-child .radio-document input[type=checkbox] {
  appearance: none;
}

.document-preview .popup-content .form-block:nth-child(2) .grid-x:last-child .radio-document input[type=checkbox]::before {
  content: "";
  text-align: center;
  width: 16px;
  height: 16px;
  display: inline-block;
  vertical-align: middle;
  border: 1px solid #bebdbd;
  background-color: #f0f0f0;
  color: #000;
  border-radius: 4px
}

.document-preview .popup-content .form-block:nth-child(2) .grid-x:last-child .radio-document input[type=checkbox].doc-info-checked:checked::before {
  content: "\2713";
  background-color: #ffffff;
  color: #000000;
}

.document-preview .popup-content .form-block:nth-child(2) .grid-x:last-child .radio-document .doc-info-checked-label {
  color: #000 !important;
}

.displayNone {
  display: none;
}

.popup .popup-container header.popup-header-content {
  outline: none;
}

/*New UI Component css*/
.center-container {
  min-height: 440px;
  max-width: 800px;
  width: 100%;
  position: absolute;
  top: 15%;
  
  border-radius: 30px;
  .new-UI-btn-content {
    display: flex;
    position: absolute;
    width: 800px;
    height: 440px;
    .new-Ui-content-area {
      flex-basis: 50%;
      background: #fff;
      border-radius: 30px 0px 0px 30px;
      padding: 4.81rem 3.12rem;
       h2 {
        padding-bottom: 20px;
        font-size: 20px;
       }
       p {
        margin-bottom: 20px;
       }
       button {
        img {
          width: 20px;
          height: 22px;
          margin-right: 9px;
        }
        span {
          font-size: 16px;
          font-weight: 600;
        }
       }
    }
    .new-UI-site-thumbnail {
      flex-basis: 50%;
      border-radius: 0 30px 30px 0;
      position: relative;
      button {
        position: absolute;
        right: 15px;
        top: 15px;
        padding: 8px 9px;
        background: #E0E0E0;
        border-radius: 50%;
        outline: none;
        cursor: pointer;
        img {
          width: 12px;
          height: 12px;
        }
      }
      > img {
        border-radius: 0 30px 30px 0 !important;
	      height: 100%;
      }
    }
  }
}
@media screen and (min-width: 1824px) {
  .center-container {
    top:25%;
  }
}